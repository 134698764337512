<script setup lang="ts">
import { SpDialog } from '@dev.smartpricing/sp-vue-components'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { useStrategiesStore } from '~/store/strategies'
import { TestIds } from '../../constants/TestIds'

const emit = defineEmits<{
	(e: 'update:modelValue', value: boolean): void
}>()
const props = defineProps<{
	modelValue: boolean
}>()
const strategiesStore = useStrategiesStore()
const dialogRef = ref<InstanceType<typeof SpDialog>>()

const isOpen = computed({
	get: () => props.modelValue,
	set: (value: boolean) => emit('update:modelValue', value),
})

const onModalClose = () => {
	dialogRef.value?.onClose()
}
const onModalConfirm = () => {
	dialogRef.value?.onClose()
	strategiesStore.resetStrategyChanges()
}
</script>

<template>
	<ClientOnly>
		<SpDialog
			ref="dialogRef"
			v-model="isOpen"
			teleport-to="#dialogs-container"
			@close="onModalClose"
			:test-ids="{ container: TestIds.StrategiesRestorePopup, closeButton: TestIds.StrategiesRestorePopupXButton }"
		>
			<template #header-top>
				<SpText :size="SpTextSize.HeadingLg01" class="self-center text-main-blue-600">
					{{ useLocale().translate(TranslationKeys.STRATEGY_CONFIGURATION_RESET_STRATEGY_CHANGES_MODAL_TITLE) }}
				</SpText>
			</template>
			<div class="flex w-full flex-col items-start gap-6 self-stretch">
				<SpText :size="SpTextSize.BodyMd02" class="text-secondary-gray-900">
					{{ useLocale().translate(TranslationKeys.STRATEGY_CONFIGURATION_RESET_STRATEGY_CHANGES_MODAL_HINT) }}
				</SpText>
			</div>
			<template #footer>
				<div class="flex items-start justify-between gap-2">
					<SpButton
						class="border-2"
						:size="SpButtonSize.Default"
						:type="SpButtonType.Secondary"
						:text="useLocale().translate(TranslationKeys.CANCEL)"
						@click="onModalClose"
						:test-ids="{
							container: TestIds.StrategiesRestorePopupCancelBtn,
						}"
					/>
					<SpButton
						class="border-2"
						:size="SpButtonSize.Default"
						:type="SpButtonType.Danger"
						:text="useLocale().translate(TranslationKeys.RESET)"
						@click="onModalConfirm"
						:test-ids="{
							container: TestIds.StrategiesRestorePopupResetBtn,
						}"
					/>
				</div>
			</template>
		</SpDialog>
	</ClientOnly>
</template>
